/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const MessageSizeDesktopLarge = '48px'
export const MessageSizeDesktopMedium = '40px'
export const MessageSizeDesktopSmall = '32px'
export const MessageSizeMobileLarge = '48px'
export const MessageSizeMobileMedium = '40px'
export const MessageSizeMobileSmall = '32px'
export const MessageBorderRadiusDesktopLarge = '6px'
export const MessageBorderRadiusDesktopMedium = '5px'
export const MessageBorderRadiusDesktopSmall = '4px'
export const MessageBorderRadiusMobileLarge = '6px'
export const MessageBorderRadiusMobileMedium = '5px'
export const MessageBorderRadiusMobileSmall = '4px'
export const MessageSpacingSmallDesktopLeft = '4px'
export const MessageSpacingSmallDesktopRight = '8px'
export const MessageSpacingSmallDesktopVertical = '4px'
export const MessageSpacingSmallDesktopGap = '4px'
export const MessageSpacingSmallMobileLeft = '4px'
export const MessageSpacingSmallMobileRight = '8px'
export const MessageSpacingSmallMobileVertical = '4px'
export const MessageSpacingSmallMobileGap = '4px'
export const MessageSpacingMediumDesktopLeft = '8px'
export const MessageSpacingMediumDesktopRight = '12px'
export const MessageSpacingMediumDesktopVertical = '8px'
export const MessageSpacingMediumDesktopGap = '8px'
export const MessageSpacingMediumMobileLeft = '8px'
export const MessageSpacingMediumMobileRight = '12px'
export const MessageSpacingMediumMobileVertical = '8px'
export const MessageSpacingMediumMobileGap = '8px'
export const MessageSpacingLargeDesktopLeft = '12px'
export const MessageSpacingLargeDesktopRight = '16px'
export const MessageSpacingLargeDesktopVertical = '12px'
export const MessageSpacingLargeDesktopGap = '12px'
export const MessageSpacingLargeMobileLeft = '12px'
export const MessageSpacingLargeMobileRight = '16px'
export const MessageSpacingLargeMobileVertical = '12px'
export const MessageSpacingLargeMobileGap = '12px'
export const MessageBorderWidthInfo = '0px'
export const MessageBorderWidthWarning = '0px'
export const MessageBorderWidthError = '0px'
export const MessageBorderWidthSuccess = '0px'
export const MessageBorderWidthUpsell = '0px'
export const MessageColorInfoBackground = '#eceff1'
export const MessageColorInfoOnBackground = '#37474f'
export const MessageColorInfoOutline = '#ffffff00'
export const MessageColorWarningBackground = '#fff3e0'
export const MessageColorWarningOnBackground = '#664A33'
export const MessageColorWarningOutline = '#ffffff00'
export const MessageColorErrorBackground = '#fbe9e7'
export const MessageColorErrorOnBackground = '#d84315'
export const MessageColorErrorOutline = '#ffffff00'
export const MessageColorSuccessBackground = '#f1f8e9'
export const MessageColorSuccessOnBackground = '#558b2f'
export const MessageColorSuccessOutline = '#ffffff00'
export const MessageColorUpsellBackground = '#f5f7f6'
export const MessageColorUpsellOnBackground = '#004D3B'
export const MessageColorUpsellOnBackgroundText = '#004D3B'
export const MessageColorUpsellOutline = '#ffffff00'
